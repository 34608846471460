<template>
      <div class="content">
        <div class="menu_content">
          <div v-for="(item,index) in datas" :key="index" :id="'menu_item_'+item.id" class="menu_item" @click="menuItemClick(item.id)">
            <RouterLink :to="item.link">{{ item.menuname }}</RouterLink>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'menu',
    data(){
      return {
        datas:[
          {id:'001',link:'/',menuname:'首页'},
          {id:'002',link:'/service',menuname:'服务范围'},
          {id:'003',link:'/contact',menuname:'联系方式'},
          {id:'004',link:'/about',menuname:'关于STRCOS'},
        ]
      }
    },
    methods:{

      menuItemClick(m){

        let elm_cs = document.getElementsByClassName('menu_item');
        if(elm_cs.length>0){
          for(var k=0;k<elm_cs.length;k++){
            let elm_cas = elm_cs[k].getElementsByTagName('a');
            if(elm_cas.length>0){
              for(var i=0;i<elm_cas.length;i++){
                elm_cas[i].style.color= 'white';
              }
            }
          }
        }
        let elm = document.getElementById('menu_item_'+m);
        let elmas = elm.getElementsByTagName('a');
        if(elmas.length>0){
          elmas[0].style.color= 'rgba(75, 145, 214,1)';
        }

      },
    }

  }
  </script>
  
  <style>
  .content {
    background-color: rgba(0, 31, 62, 0.9);
    height: 64px;
  }
  .company_name {
    color:aliceblue;
    font-weight: bold;
    float: left;
  }
  .menu_content {
    width: 100vw;
    display: flex;
    justify-content: right;
  }
  .menu_item {
    display: flex;
    justify-content: flex-start; 
    margin-right: 2vw;
    color: white;
    line-height: 64px;
  }
  .menu_item a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  .menu_item_text {
    color: rgba(75, 145, 214,0.9);
    text-decoration: none;
    font-weight: bold;
  }
  #menu_item_001 a {
    color: rgba(75, 145, 214,1)
  }
  </style>