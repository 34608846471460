<template>
    <div class="b_content">
      <div class="bottom_content">

        <div v-for="(item,index) in datas" :key="index" :id="'bottom_item_'+item.id" class="bottom_item">
          <div><a :href="item.href">{{ item.code }}</a></div>
        </div>

      </div>
    </div>
</template>

<script>

export default {
  name: 'bottom_',
  data(){
    return {
      datas: [
        {id:'cc001', code:'闽ICP备2024060711号-1',href:'https://beian.miit.gov.cn'},
        {id:'cc002', code:''}
      ]
    }
  },
  methods:{

  }
}
</script>

<style>
.b_content {
    background-color: rgba(0, 31, 62, 0.9);
    padding: 6px 0px 6px 0px;
    position: absolute;
    width: 100%;
    bottom: 0px;
}
.bottom_item {
    color: white;
    font-size: 12px;

    display:inline-block;
    justify-content: center;
}
.bottom_item a {
  color: rgb(132, 186, 240);
  text-decoration: none;
}
</style>