<template>
  <div>
    <Menu></Menu>
  </div>
  <div class="views">
    <router-view></router-view>
  </div>
  <div class="bottom_info">
    <Bottom></Bottom>
  </div>
</template>

<script>
import Menu from './components/menu/index.vue'
import Bottom from './components/bottom/index.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Bottom
  },
  data(){
    return {

    }
  },
  methods:{

  },
  mounted() {

  }
}
</script>

<style>
body {
  margin: 0px;
  overflow-y: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:#FFFFFF;
}
.logo {
  width: 80px;
  height: 45px;
}
.views {
  clear: both;
}
.content_ {
    padding: 15vh 10vw 0ch 10vw;
    text-align: left;
    justify-content: left;
}
.content_item_2 {
    text-align: left;
    justify-content: left;
}
</style>
