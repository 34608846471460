import { createRouter, createWebHistory } from 'vue-router';
const title = 'STRCOS';
const routes = [
  {
    name: 'home', 
    path: '/', component: () => import('../components/home/index.vue'),
    meta: { title: '厦门虎鲸梦想网络科技有限公司' }
  },
  { name: 'service', 
    path: '/service', component: () => import('../components/service/index.vue'), 
    meta: { title: title } 
  },
  { name: 'about', path: '/about', component: () => import('../components/about/index.vue'), 
    meta: { title: title } 
  },
  { name: 'contact', path: '/contact', component: () => import('../components/contact/index.vue'), 
    meta: { title: title } 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;